exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-danier-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/danier/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-danier-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-orderease-1-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/orderease1/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-orderease-1-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-translation-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/translation/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-translation-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-vanta-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/vanta/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-vanta-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-workforce-warriors-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/workforce-warriors/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-portfolio-workforce-warriors-index-mdx" */)
}

